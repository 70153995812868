import { isPlatformBrowser } from '@angular/common';
import {
    ComponentRef,
    DestroyRef,
    Directive,
    ElementRef,
    HostListener,
    InputSignal,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    Renderer2,
    ViewContainerRef,
    inject,
    input,
} from '@angular/core';
import { outputToObservable, takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EbScrollbarComponent } from './scrollbar/scrollbar.component';

@Directive({
    selector: '[ebScrollbar]',
})
export class ScrollbarDirective implements OnInit, OnDestroy {
    private readonly _elementRef = inject(ElementRef<HTMLElement>);
    private readonly _vcr = inject(ViewContainerRef);
    private readonly _renderer = inject(Renderer2);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _destroyRef = inject(DestroyRef);

    private _resizeObserver: ResizeObserver | undefined;
    private _scrollbarRef!: ComponentRef<EbScrollbarComponent>;
    private _scrollPositionExpected: number | undefined;

    dark: InputSignal<boolean> = input(false);
    fullWidthMobile: InputSignal<boolean> = input(false);
    y: InputSignal<boolean> = input(false);

    private get _scrollLeft(): number {
        return this.y() ? this._elementRef.nativeElement.scrollTop : this._elementRef.nativeElement.scrollLeft;
    }

    private set _scrollLeft(value: number) {
        if (this.y()) {
            this._elementRef.nativeElement.scrollTop = value;
        } else {
            this._elementRef.nativeElement.scrollLeft = value;
        }
    }

    private get _containerWidth(): number {
        return this.y() ? this._elementRef.nativeElement.offsetHeight : this._elementRef.nativeElement.offsetWidth;
    }

    private get _contentWidth(): number {
        return this.y() ? this._elementRef.nativeElement.scrollHeight : this._elementRef.nativeElement.scrollWidth;
    }

    private get _scrollBarWidth(): number {
        return this.y()
            ? this._scrollbarRef.instance.scrollbarTrack().nativeElement.offsetHeight
            : this._scrollbarRef.instance.scrollbarTrack().nativeElement.offsetWidth;
    }

    @HostListener('scroll') protected onScroll(): void {
        this.refreshScrollbarPosition();
    }

    public refreshScrollbarPosition(): void {
        this._calculateScrollBarPosition();
        this._checkScrollPositionExpected();
    }

    public ngOnInit(): void {
        this._initScrollbar();
        this._initResizeObserver();
    }

    public ngOnDestroy(): void {
        this._resizeObserver?.disconnect();
    }

    private _initResizeObserver(): void {
        if (isPlatformBrowser(this._platformId)) {
            this._resizeObserver = new ResizeObserver(() => {
                this._calculateScrollBarWidth();
            });

            this._resizeObserver.observe(this._elementRef.nativeElement);
        } else {
            this._calculateScrollBarWidth();
        }
    }

    private _initScrollbar(): void {
        this._scrollbarRef = this._vcr.createComponent(EbScrollbarComponent);
        this._scrollbarRef.instance.dark.set(this.dark());
        this._scrollbarRef.instance.vertical.set(this.y());
        this._scrollbarRef.instance.fullWidthMobile.set(this.fullWidthMobile());

        outputToObservable(this._scrollbarRef.instance.startScroll)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this._setSnapType(false);
            });

        outputToObservable(this._scrollbarRef.instance.stopScroll)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this._fixScrollPosition();
            });

        outputToObservable(this._scrollbarRef.instance.moveScroll)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((diff: number) => {
                this._setScrollLeft(diff);
            });

        outputToObservable(this._scrollbarRef.instance.scrollTo)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((offset: number) => {
                this._scrollTo(offset);
            });
    }

    private _calculateScrollBarWidth(): void {
        this._scrollbarRef.instance.thumbWidth.set((this._containerWidth / this._contentWidth) * 100);
    }

    private _calculateScrollBarPosition(): void {
        this._scrollbarRef.instance.thumbPosition.set(
            this.y() ? this._scrollLeft : (this._scrollLeft / this._contentWidth) * this._scrollBarWidth,
        );
    }

    private _fixScrollPosition(): void {
        if (this.y()) {
            return;
        }

        const item = this._findClosestItem(this._scrollLeft);

        if (item && item.offsetLeft !== this._scrollLeft) {
            this._scrollPositionExpected = item.offsetLeft;
            this._elementRef.nativeElement.scrollTo({
                left: item.offsetLeft,
                behavior: 'smooth',
            });
        } else {
            this._setSnapType(true);
        }
    }

    private _checkScrollPositionExpected(): void {
        if (this._scrollPositionExpected === this._scrollLeft) {
            this._scrollPositionExpected = undefined;
            this._setSnapType(true);
        }
    }

    private _setSnapType(xMandatory = false): void {
        this._renderer.setStyle(
            this._elementRef.nativeElement,
            'scroll-snap-type',
            xMandatory ? 'x mandatory' : 'none',
        );
    }

    private _setScrollLeft(diff: number): void {
        this._scrollLeft = this._scrollLeft + (diff * this._contentWidth) / this._scrollBarWidth;
    }

    private _scrollTo(offsetScrollbar: number): void {
        const fraction = offsetScrollbar / this._scrollBarWidth;
        const offsetContent = Math.min(
            Math.max(this._contentWidth * fraction - this._containerWidth / 2, 0),
            this._contentWidth - this._containerWidth,
        );

        const item = this._findClosestItem(offsetContent);

        this._elementRef.nativeElement.scrollTo({
            left: item?.offsetLeft ?? offsetContent,
            behavior: 'smooth',
        });
    }

    private _findClosestItem(scrollLeft: number): HTMLElement | undefined {
        const items = this._elementRef.nativeElement.querySelectorAll('.eb-carousel-item') as NodeListOf<HTMLElement>;
        return (
            [].find.call(items, (item: HTMLElement) => scrollLeft <= item.offsetLeft + item.offsetWidth / 2) ?? items[0]
        );
    }
}
