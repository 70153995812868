<div class="carousel relative" [ngClass]="{ 'carousel--full-width-mobile': fullWidthMobile() }">
  <div #carouselFrameRef class="carousel__frame scroll-hide overflow-x-auto" ebScrollbar [dark]="dark()" [fullWidthMobile]="fullWidthMobile()">
    <div #carouselItemsRef class="carousel__items" [ngClass]="{ 'carousel__items--padding': !isGallery() }">
      <ng-content />
    </div>
  </div>
  <div class="carousel-controls">
    @if(isNavEnabled()) {
      <button
        eb-button
        [ebOnlyIcon]="true"
        aria-label="Przewiń w lewo"
        ebType="secondary"
        ebSize="small"
        (click)="scrollBy(isGallery() ? 1 : numberOfItemsToScrollBy, CarouselNavigationDirections.LEFT)"
        class="carousel-control__prev absolute z-1"
      >
        <eb-icon key="chevron-left" size="mdr" />
      </button>
    }
    @if(isNavEnabled()) {
      <button
        eb-button
        [ebOnlyIcon]="true"
        aria-label="Przewiń w prawo"
        ebType="secondary"
        ebSize="small"
        (click)="scrollBy(isGallery() ? 1 : numberOfItemsToScrollBy, CarouselNavigationDirections.RIGHT)"
        class="carousel-control__next absolute z-1"
      >
        <eb-icon key="chevron-right" size="mdr" />
      </button>
    }
  </div>
</div>
