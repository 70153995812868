import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    inject,
    output,
    OutputEmitterRef,
    Renderer2,
    Signal,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';

@Component({
    selector: 'eb-scrollbar',
    templateUrl: './scrollbar.component.html',
    styleUrls: ['./scrollbar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbScrollbarComponent {
    private readonly _renderer = inject(Renderer2);

    thumbWidth: WritableSignal<number> = signal(100);
    isActive: Signal<boolean> = computed(() => this.thumbWidth() < 100);

    thumbPosition = signal(0);
    scrolled: WritableSignal<boolean> = signal(false);
    startScrollPosition = 0;
    dark: WritableSignal<boolean> = signal(false);
    vertical: WritableSignal<boolean> = signal(false);
    fullWidthMobile: WritableSignal<boolean> = signal(false);

    moveListener: (() => void | undefined) | undefined;
    mouseUpListener: (() => void | undefined) | undefined;

    readonly startScroll: OutputEmitterRef<void> = output<void>();
    readonly stopScroll: OutputEmitterRef<void> = output<void>();
    readonly moveScroll: OutputEmitterRef<number> = output<number>();
    readonly scrollTo: OutputEmitterRef<number> = output<number>();

    readonly scrollbarTrack = viewChild.required<ElementRef>('scrollbarTrack');

    protected _startScroll(event: MouseEvent): void {
        this.startScrollPosition = this.vertical() ? event.pageY : event.pageX;
        this.scrolled.set(true);
        this.startScroll.emit();
        this.moveListener = this._renderer.listen('window', 'mousemove', (_event: MouseEvent) =>
            this._moveScroll(_event),
        );
        this.mouseUpListener = this._renderer.listen('window', 'mouseup', () => this._stopScroll());
    }

    private _stopScroll(): void {
        if (this.scrolled()) {
            this.stopScroll.emit();
        }

        if (this.moveListener) {
            this.moveListener();
        }

        if (this.mouseUpListener) {
            this.mouseUpListener();
        }

        this.scrolled.set(false);
    }

    private _moveScroll(event: MouseEvent): void {
        if (this.scrolled()) {
            const diff = (this.vertical() ? event.pageY : event.pageX) - this.startScrollPosition;
            this.startScrollPosition = this.vertical() ? event.pageY : event.pageX;
            this.moveScroll.emit(diff);
        }
    }

    protected onTrackClick(event: MouseEvent): void {
        this.scrollTo.emit(event.offsetX);
    }
}
