import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    HostListener,
    InputSignal,
    OnDestroy,
    PLATFORM_ID,
    Signal,
    ViewEncapsulation,
    WritableSignal,
    computed,
    contentChildren,
    inject,
    input,
    signal,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { UiScrollbarModule } from '@e-bilet/ui-scrollbar';
import { ScrollbarDirective } from 'libs/ui-scrollbar/src/lib/scrollbar.directive';
import { filter, map, startWith } from 'rxjs/operators';
import { DeviceService } from '../../../../device/src/lib/device.service';
import { IconComponent } from '../../../../ui-icons/src/lib/icon/icon.component';
import { EbCarouselItemDirective } from '../carousel-item.directive';

export enum CarouselNavigationDirections {
    RIGHT,
    LEFT,
}

@Component({
    selector: 'eb-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UiButtonsModule, UiScrollbarModule, IconComponent],
})
export class EbCarouselComponent implements AfterViewInit, OnDestroy {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _deviceService = inject(DeviceService);
    private readonly _destroyRef = inject(DestroyRef);

    fullWidthMobile: InputSignal<boolean> = input(true);
    dark: InputSignal<boolean> = input(false);
    isGallery: InputSignal<boolean> = input(false);

    private _isLastItemInFrame: WritableSignal<boolean> = signal(true);
    private _isFirstItemInFrame: WritableSignal<boolean> = signal(true);
    private _numberOfVisibleItems: WritableSignal<number> = signal(0);
    private _itemWidth: WritableSignal<number> = signal(0);
    private readonly FIRST_ITEM_INDEX: number = 0;
    private lastItemIndex: Signal<number> = computed(() => this._carouselItems().length - 1);
    private _lastItemIntersectionObserver: IntersectionObserver | undefined;
    private _firstItemIntersectionObserver: IntersectionObserver | undefined;

    isNavEnabled: Signal<boolean | undefined> = toSignal(
        this._deviceService.isMobile$.pipe(takeUntilDestroyed()).pipe(map((res: boolean) => !res)),
    );

    readonly desktopRemSize = 16;
    readonly CarouselNavigationDirections = CarouselNavigationDirections;

    @HostListener('window:resize', ['$event'])
    protected onResize(): void {
        if (!this._itemWidth()) {
            this._countItemWidth();
        }
        this._countVisibleItems();
    }

    public set isLastItemInFrame(is: boolean) {
        if (is !== this._isLastItemInFrame()) {
            this._isLastItemInFrame.set(is);
        }
    }

    public get isLastItemInFrame(): boolean {
        return this._isLastItemInFrame();
    }

    public set isFirstItemInFrame(is: boolean) {
        if (is !== this._isFirstItemInFrame()) {
            this._isFirstItemInFrame.set(is);
        }
    }

    public get isFirstItemInFrame(): boolean {
        return this._isFirstItemInFrame();
    }

    protected get prevEnabled(): boolean {
        return !!this.isNavEnabled() && !this.isFirstItemInFrame;
    }

    protected get nextEnabled(): boolean {
        return !!this.isNavEnabled() && !this.isLastItemInFrame;
    }

    protected get numberOfItemsToScrollBy(): number {
        return this._numberOfVisibleItems() - 1;
    }

    private get _gap(): number {
        return this.desktopRemSize;
    }

    readonly carouselFrameRef = viewChild.required<ElementRef>('carouselFrameRef');
    readonly scrollbar = viewChild.required<ScrollbarDirective>(ScrollbarDirective);

    private readonly _carouselItems = contentChildren<EbCarouselItemDirective>(EbCarouselItemDirective);
    private readonly _carouselItems$ = toObservable(this._carouselItems);

    public ngAfterViewInit(): void {
        this._carouselItems$
            .pipe(
                startWith(null),
                filter(() => !!this._carouselItems().length),
                takeUntilDestroyed(this._destroyRef),
            )
            .subscribe(() => {
                this._createIntersectionObservers();
            });

        if (!this.isGallery()) {
            this._countItemWidth();
            this._countVisibleItems();
        }
    }

    public ngOnDestroy(): void {
        this._destroyIntersectionObservers();
    }

    public goTo(id: string, manuallyRefreshScrollbarPosition?: boolean): void {
        const item = this._carouselItems().find((i) => i.ebCarouselItemId() === id);
        if (item) {
            (item.elementRef.nativeElement as HTMLDivElement).scrollIntoView();
            if (manuallyRefreshScrollbarPosition) {
                this.scrollbar().refreshScrollbarPosition();
            }
        }
    }

    private _getItem(idx: number): HTMLDivElement | null {
        return this._carouselItems()[idx]?.elementRef.nativeElement || null;
    }

    private _getWidth(item: Element | null): number {
        if (item && isPlatformBrowser(this._platformId)) {
            return item.clientWidth + parseInt(window.getComputedStyle(item).marginRight, 10);
        } else {
            return 0;
        }
    }

    public reset(): void {
        this.goTo(this._carouselItems()[this.FIRST_ITEM_INDEX].ebCarouselItemId());
    }

    public scrollBy(itemsToScrollBy: number, direction: CarouselNavigationDirections): void {
        if (this.isGallery() && !this._itemWidth()) {
            this._countItemWidth();
            this._numberOfVisibleItems.set(1);
        }
        const frameDiv = this.carouselFrameRef().nativeElement as HTMLDivElement;

        let delta = itemsToScrollBy * (this._itemWidth() + this._gap);
        if (direction === CarouselNavigationDirections.LEFT) {
            delta = -delta;
        }

        if (!this.nextEnabled && direction === CarouselNavigationDirections.RIGHT) {
            frameDiv.scrollBy({ left: -frameDiv.scrollLeft, behavior: 'instant' });
        } else if (!this.prevEnabled && direction === CarouselNavigationDirections.LEFT) {
            const maxScrollLeft = frameDiv.scrollWidth - frameDiv.clientWidth;
            frameDiv.scrollBy({ left: maxScrollLeft - frameDiv.scrollLeft, behavior: 'instant' });
        } else {
            frameDiv.scrollBy({ left: delta, behavior: 'smooth' });
        }
    }

    private _destroyIntersectionObservers(): void {
        if (this._lastItemIntersectionObserver) {
            this._lastItemIntersectionObserver.disconnect();
        }

        if (this._firstItemIntersectionObserver) {
            this._firstItemIntersectionObserver.disconnect();
        }
    }

    private _createIntersectionObservers(): void {
        if (isPlatformBrowser(this._platformId)) {
            const options = {
                root: this.carouselFrameRef().nativeElement,
                rootMargin: '0px',
                threshold: 0.5,
            };

            this._lastItemIntersectionObserver = new IntersectionObserver((entries) => {
                this.isLastItemInFrame = entries.some((e) => e.isIntersecting);
            }, options);

            this._firstItemIntersectionObserver = new IntersectionObserver((entries) => {
                this.isFirstItemInFrame = entries.some((e) => e.isIntersecting);
            }, options);

            if (this._carouselItems()[this.lastItemIndex()]) {
                this._lastItemIntersectionObserver.observe(
                    this._carouselItems()[this.lastItemIndex()].elementRef.nativeElement,
                );
            }

            if (this._carouselItems()[this.FIRST_ITEM_INDEX]) {
                this._firstItemIntersectionObserver.observe(
                    this._carouselItems()[this.FIRST_ITEM_INDEX].elementRef.nativeElement,
                );
            }
        }
    }

    private _countItemWidth(): void {
        this._itemWidth.set(this._getWidth(this._getItem(0)));
    }

    private _countVisibleItems(): void {
        this._numberOfVisibleItems.set(
            Math.round(this.carouselFrameRef().nativeElement.offsetWidth / (this._itemWidth() + this._gap)),
        );
    }
}
